import React, { useEffect, useState } from 'react';
import moment from 'moment';
import './coming-soon.css'

const Countdown: React.FC = () => {
    const deadline = moment('2024-07-24')   ;

    const calculateTimeLeft = () => {
        const now = moment();
        const difference = deadline.diff(now);

        let timeLeft = {
            days: '00',
            hours: '00',
            minutes: '00',
            seconds: '00',
        };

        if (difference > 0) {
            timeLeft = {
                days: String(deadline.diff(now, 'days')).padStart(2, '0'),
                hours: String(deadline.diff(now, 'hours') % 24).padStart(2, '0'),
                minutes: String(deadline.diff(now, 'minutes') % 60).padStart(2, '0'),
                seconds: String(deadline.diff(now, 'seconds') % 60).padStart(2, '0'),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    return (
        <div className="container">
            <div className="row">
                <div className="left-wrap col-12 col-md-5">
                    <div className="left" style={styles.counterbg}>

                        <p><span className="days">{timeLeft.days}</span><span className="small"> days</span></p>
                        <p><span className="hours">{timeLeft.hours}</span><span className="small"> hours</span></p>
                        <p>&nbsp;&nbsp;<span className="minutes">{timeLeft.minutes}</span><span className="small"> minutes</span></p>
                        <p>&nbsp;&nbsp;<span className="seconds">{timeLeft.seconds}</span><span className="small"> seconds</span></p>
                    </div>
                </div>
                <div className="col-12 col-md-7 right">
                    <div className="mainInfo">
                        <img src={`${process.env.PUBLIC_URL}/img/logo.png`} alt="Logo" style={styles.logo} />
                        <h1>COMING SOON</h1>
                        <p className="slogan">Bringing Home Closer</p>
                    </div>
                    {/* <ul className="icon-list">
                        <li className="icon"><a className="twitter" href="https://google.com"></a></li>
                        <li className="icon"><a className="linkedin" href="https://google.com"></a></li>
                        <li className="icon"><a className="facebook" href="https://google.com"></a></li>
                        <li className="icon"><a className="instagram" href="https://google.com"></a></li>
                        <li className="icon"><a className="youtube" href="https://google.com"></a></li>
                        <li className="icon"><a className="github" href="https://google.com"></a></li>
                        <li className="icon"><a className="stackoverflow" href="https://google.com"></a></li>
                        <li className="icon"><a className="medium" href="https://google.com"></a></li>
                    </ul> */}
                </div>
            </div>
        </div>
    );
};

const styles = {
    logo: {
        height: '10rem',
        marginBottom: '20px',
        width: '20rem'
    },
    counterbg: {
        backgroundImage: 'url("/img/247.jpg")',
    }
};

export default Countdown;
