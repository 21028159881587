import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ComingSoonPage from './pages/coming-soon';

const AppRouter: React.FC = () => {
    return (
        <Router>
            <Routes>
                {/* Redirect all paths to the ComingSoon component */}
                <Route path="*" element={<ComingSoonPage />} />
            </Routes>
        </Router>
    );
};

export default AppRouter;
